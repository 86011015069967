<template>
   <div>
       <common-header>{{title}}</common-header>
        <div class="container">
            <div @click="toHref(item)" class="wrapper" :class="`bg-${from} text-${from}`" v-for="item in currentList" :key="item.title">
                <div class="title">{{item.title}}</div>
                <div class="desc">{{item.desc}}</div>
                <span v-if="item.remmonend" class="recommend" :class="`tag-${from}`">推薦</span>
            </div>
        </div>
   </div>
</template>
<script>
import CommonHeader from '@/components/header.vue'
import {postCode} from '@/utils/tools'
export default {
    data() {
        return {
            zx: [{
                id: 0,
                remmonend: false,
                code: 'tw_zxzx',
                short: 'twzx',
                belong: 'zx',
                title: '台灣醫生在線咨詢',
                desc: '與台灣醫生一對一在線問診',
                href: ''
            }, {
                id: 1,
                remmonend: true,
                short: 'dlzx',
                belong: 'zx',
                title: '大陸醫生在線咨詢',
                desc: '與大陸醫生一對一在線問診',
                href: ''
            }],
            zj: [{
                id: 0,
                remmonend: true,
                code: 'zjyy',
                short: 'zjyy',
                belong: 'zj',
                title: '專家預約',
                desc: '專家號預約',
                href: ''
            }, {
                id: 1,
                remmonend: false,
                code: 'lvt',
                short: 'lstd',
                belong: 'zj',
                title: '綠色通道',
                desc: '高級專家號預約',
                href: ''
            }],
            yp: [{
                id: 0,
                remmonend: true,
                code: 'tw_yj',
                short: 'twyj',
                belong: 'yp',
                title: '台灣藥局',
                desc: '購買台灣當地非處方用藥',
                href: ''
            }, {
                id: 1,
                remmonend: false,
                short: 'dlyj',
                belong: 'yp',
                title: '大陸藥局',
                desc: '購買大陸本地非處方用藥',
                href: ''
            }],
            title: '',
            currentList: [],
            from: '',
            obj: {}
        }
    },
    components: {
        CommonHeader
    },
    mounted() {
        this.obj = {
            'zx': this.zx,
            'zj': this.zj,
            'yp': this.yp
        }
        this.title = this.$route.query['title']
        this.from = this.$route.query['from']
        this.currentList = this.obj[this.from ]
        console.log('this.currentList,', this.currentList)
    },
    methods: {
        toHref(item) {
            let {id, short, title, belong} = item;
            if(item.code) {
                postCode(item.code)
            }
            switch(short) {
                case 'twzx':
                case 'lstd':
                case 'twyj':
                    this.$router.push(`/noOpen?title=${title}`)
                    break;
                case 'dlzx':
                case 'dlyj':
                    this.$router.push(`/three?from=${belong}&title=${title}&id=${this.$route.query['id']}`)
                    break;
                case 'zjyy':
                    this.$router.push('/container?from=zjyy&title=專家預約')
                    break;
                default:
                    window.location.href = this.obj[this.from][id]['href']
                    break;
            }
            // // 專家預約
            // if(index == 1 && this.from == 'zj') {
            //     this.$router.push('/noOpen?title=綠色通道')
            //     return;
            // }
            // // 大陸的在線問診跳轉
            // if(index == 1 && this.from == 'zx') {
            //     this.$router.push('/three?from=zx&title=在線診療')
            //     return;
            // }else if(index == 1 && this.from == 'yp') {
            //     this.$router.push('/three?from=yp&title=藥品導購')
            //     return;
            // }

            // window.location.href = this.obj[this.from][index]['href']
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
    padding-top: 160px;
    box-sizing: border-box;
    background: #fff;
    min-height: 100vh;
}
.wrapper {
    width: 305px;
    height: 120px;
    // background: #EAF7F6;
    border-radius: 20px;
    margin: 0 auto;
    margin-bottom: 49px;
    padding: 31px 0 0 28px;
    box-sizing: border-box;
    text-align: left;
    position: relative;

    .recommend {
        display: block;
        position: absolute;
        right: 0;
        top: -4px;
        width: 40px;
        height: 20px;
        // background: #02C2C3;
        border-radius: 20px;
        font-size: 12px;
        color: #fff;
        text-align: center;
    }

    .title {
        font-size: 20px;
        // color: #2F9397;
    }

    .desc {
        font-size: 14px;
        // color: #2F9397;
    }
}
</style>